import request from '@/utils/request-1'
export function getEasyToken(user_id) {
  return request({
      url: '/imapi/Token/getEasyToken',
      method: 'get',
      params: {
        user_id
      }
  })
}
